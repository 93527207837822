<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="listOEM"
      :filter-names="['search', 'make', 'model']"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:battery_id="{ item }">
        <td>
          <span v-if="item.battery_slot_type == 'fixed'">
            <v-btn
              primary
              icon
              class="plain-text open-link"
            >
              <!-- @click="goToBatteryDiagnostic(item)" -->
              <router-link :to="goToBatteryDiagnostic(item)">
                {{ item.battery_id }}
              </router-link>
            </v-btn>
          </span>

          <span v-if="item.battery_slot_type == 'swappable'">
            <span v-if="item.vehicle_type == 'two_wheeler'">
              <v-btn
                primary
                icon
                class="plain-text open-link"
              >
                <!-- @click="goToBatteryDiagnostic(item)" -->
                <router-link :to="goToBatteryDiagnostic(item)">
                  {{ item.battery_id }}
                </router-link>
              </v-btn>
            </span>
            <span v-if="item.vehicle_type == 'three_wheeler'">
              <div class="link-hover">
                <span
                  v-for="(b, i) in item.battery_ids"
                  :key="i"
                  :class="
                    item.battery_bms_ids[i] ? 'active open-link' : 'not-active'
                  "
                >
                  <span v-if="item.battery_bms_ids[i]">
                    <router-link
                      :to="
                        goToBatteryDiagnostic(item, item.battery_bms_ids[i], b)
                      "
                    >
                      {{ b }}
                    </router-link>
                  </span>
                  <span v-else>{{ b }}</span>
                  <!-- <span
                    @click="
                      item.battery_bms_ids[i]
                        ? goToBatteryDiagnostic(
                            item,
                            item.battery_bms_ids[i],
                            b
                          )
                        : ''
                    "
                  >
                    {{ item.battery_ids[i + 1] >= 0 ? `${b}, ` : b }}
                  </span> -->
                </span>
              </div>
            </span>
          </span>
        </td>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters> </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <refresh-button
          v-if="list.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        />
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      headers: [
        {
          text: this.$t("battery_id"),
          align: "left",
          sortable: false,
          value: "battery_id",
          width: "10%",
        },
        {
          text: this.$t("chassis_no"),
          align: "left",
          sortable: false,
          value: "chasis_no",
          width: "10%",
        },
        {
          text: this.$t("customer_name"),
          align: "left",
          sortable: false,
          con: true,
          value: "customer_name",
          width: "10%",
        },

        {
          text: this.$t("make_and_model"),
          align: "left",
          sortable: false,
          value: "make_and_model",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "customerVehicles/getIsLoadingCustomerVehicles",
      meta: "customerVehicles/getCustomerVehiclesMeta",
      list: "customerVehicles/getCustomerVehiclesList",
    }),
    _headers() {
      const h = [];

      this.headers.forEach((el) => {
        if (el.con) {
          if (this.options.product == "oem") {
            el.text = this.$t("customer_name");
            el.value = "customer_name";
          } else if (this.options.product == "fleet") {
            el.text = this.$t("driver_name");
            el.value = "driver_name";
          }
        }
        h.push(el);
      });

      return h;
    },

    // Remove  ICE vehicle
    listOEM() {
      const listOEM = this.list.filter((r) => r.vehicle_tech !== "ice");
      return listOEM;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "battery_diagnostics",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("customerVehicles/list", this.options)
        .then(() => {
          // if (this.options.page > (this.customerVehiclesMeta?.lastPage || 1)) {
          //   this.options.page = 1;
          // }
        });
    },
    goToBatteryDiagnostic(item, id, bt_id) {
      //id, bt_id
      // const params = {
      //   vehicle_id: item.vehicle_id,
      //   battery_id: bt_id ?? item.battery_id,
      // };

      const params = {
        // product: this.options.product,
        // fleetId: this.options.fleetId ?? undefined,
        // oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
        // tenant_id: this.options.tenant_id,
        // search: this.options.search ?? undefined,
        battery_id: bt_id ?? item.battery_id,
        vehicle_id: item.vehicle_id,
        type: item.vehicle_type,
        // page: this.options.page,
        // perPage: this.options.itemsPerPage,
      };
      if (item.vehicle_type == "three_wheeler") {
        // query.bmsId = 9240629
        params.bmsId = id;
      }
      // return this.$router.push({
      //   name: "battery_diagnostics_list",
      //   params: { imei: item.iot_imei },
      //   query: { ...this.options, ...params },
      // });
      return {
        name: "battery_diagnostics_list",
        params: { imei: item.iot_imei },
        query: { ...this.options, ...params },
      };
    },
    checkbatteryStatus(status) {
      if (status <= 20) {
        return "mdi-battery-charging-20";
      } else if (status <= 50) {
        return "mdi-battery-charging-50";
      } else if (status <= 80) {
        return "mdi-battery-charging-80";
      } else {
        return "mdi-battery-charging-100";
      }
    },
  },
};
</script>
<style scoped>
.plain-text {
  background-color: transparent !important;
  color: inherit !important;
}
.link {
  color: #20a390 !important;
}
</style>
<style lang="sass" scoped>
.link-hover
  .active
    cursor: pointer
    &:hover
      color: #20a390 !important


  .v-list-item__title
    cursor : pointer
    &:hover
      color: #20a390 !important
      i
        color: #20a390 !important

.sync-column
  cursor: pointer
  transition: all 0.5s ease
  &:hover
    color: #20a390 !important

.not-active
  pointer-events: none !important

.active
  i
    color: #4CAF50 !important

.inactive
  i
    color: #f0ae38 !important
.offline
  i
    color: #ff0000 !important
</style>
